import { ActionState } from '../../../interfaces/enums/action-state';
import { LayoutType } from '../../../interfaces/enums/layout-type';
import { PortalType } from '../../../interfaces/enums/portal-type';
import { Referrer } from '../../../interfaces/enums/referrer';
import { defineComponent, onMounted, defineAsyncComponent } from 'vue';
import app from './app.class';
import PopUp from './PopUp/PopUp.vue';
import { setupIncentive } from './incentive-base';

export default defineComponent({
	components: {
		PopUp,
		Main: defineAsyncComponent(() => import('./Layouts/Main/Main/Main.vue')),
		ProfileQuestionnaires: defineAsyncComponent(
			() => import('./Layouts/ProfileQuestionnaires/ProfileQuestionnaires/ProfileQuestionnaires.vue'),
		),
		ExclusiveAccess: defineAsyncComponent(
			() => import('./Layouts/ExclusiveAccess/ExclusiveAccess/ExclusiveAccess.vue'),
		),
		Header: defineAsyncComponent(() => import('./Layouts/Header/Header/Header.vue')),
		Reviews: defineAsyncComponent(() => import('./Layouts/Reviews/Reviews/Reviews.vue')),
		UnibuddyIncentive: defineAsyncComponent(
			() => import('./Layouts/UnibuddyIncentive/UnibuddyTemplate/UnibuddyTemplate.vue'),
		),
		ConfirmForgotPassword: defineAsyncComponent(
			() => import('./FormSections/ConfirmForgotPassword/ConfirmForgotPassword.vue'),
		),
		ChangePasswordForm: defineAsyncComponent(() => import('./Form/ChangePasswordForm/ChangePasswordForm.vue')),
		ATPIncentive: defineAsyncComponent(() => import('./Layouts/ATPIncentive/ATPIncentive.vue')),
		Copilot: defineAsyncComponent(() => import('./Layouts/CopilotIncentive/CopilotIncentive.vue')),
	},
	props: {
		layout: String,
		referrer: String,
		tab_to_show: String,
		portal_type: String,
		popup: Boolean,
		flowState: String,
		appIdentifier: String,
	},
	setup: (props) => {
		const component = setupIncentive(
			new app(
				props.layout as LayoutType,
				props.referrer as Referrer,
				props.tab_to_show as ActionState,
				props.portal_type as PortalType,
				props.popup,
				props.appIdentifier as string,
			),
		);
		onMounted(component.mounted.bind(component));

		return {
			layout: component.layout,
			layoutTypes: component.layoutTypes,
			popUpType: component.popUpType,
			referrer: component.referrer,
			mountInPopUp: component.mountInPopUp,
			flowState: props.flowState,
		};
	},
});
